import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import WorkImg from "../components/WorkImg";
import WorldCreation from "../components/WorldCreation";
import GameMechanics from "../components/GameMechanics";
import WindowSize from "../utils/WindowSize";

const WIP = () => {
    const firstLine = "Here are some of my passion projects, experimentations and spare time activities I like to work on using a variety of tools.\n";
    const secondLine = "I really love world creation and generating procedural environments, using World Machine, TerreSculptor, Unity Engine and Unreal Engine 5. \n";
    const thirdLine = "My hope is to someday create my dream game by leaveraging my past healthcare and pharmaceutical knowledge and qualifications \n";
    const fourthLine = "to create an immersive, and fun, semi-realistic health and healing mechanic to simulates physiology, disease, diagnosis, pharmaceutical manufacturing and treatment. \n";
    const fifthLine = "While it will be a simplified simulation, I want more out of my healing mechanics than simply bandages and instantly created, instant cure-all potions";

   var {width} = WindowSize();
  var size = 40;

  if( width < 750 && width > 500)
  {
    size = 40*width/750;    
  } 
  else if (width < 500)
  {
    size = 30;
  }

  return (    
    <div>
    <Navbar />
      <Navbar />
      <WorkImg heading="Work in Progress" text={firstLine + secondLine + thirdLine + fourthLine + fifthLine} size = {size}/> 
      <WorldCreation/>
      <GameMechanics/>        
      <Footer />      
    </div>
  );
};

export default WIP;