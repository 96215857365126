import "./PortfolioStyles.css";

import React, { Component } from "react";

class PortfolioImg extends Component { 

  render() {
    return (
      <div className="portfolio-img">
        <div className="heading">
          <h1 style = {{fontSize: this.props.size }}>{this.props.heading}</h1>
          <br></br>
          <p>{this.props.text.split('\n').map(str=> <p style = {{fontSize: this.props.size/2 }}>{str}</p>)}</p>
        </div>
      </div>
    );
  }
}

export default PortfolioImg;
