import "./FooterStyles.css";


import React from "react";

import {
  FaHome,
  FaGlobe,
  FaMailBulk,   
  FaLinkedin,
  FaYoutube,
  FaGithub,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <div>
              <p>Perth, Western Australia</p>              
            </div>
          </div>          
          <div className="email">
            <h4>
              <FaMailBulk size={20} style={{ color: "#fff", marginRight: "2rem" }}/>
              developer@markdevereux.net
            </h4>
          </div>
        </div>

        <div className="right">
          <h4>
            <FaGlobe size={20} style={{ color: "#fff", marginRight: "2rem" }}/>            
              Under Construction: Yurei Applications        
            </h4>                      
          <div className="social">
            <a href={'https://au.linkedin.com/in/mark-devereux-06b548154?trk=people-guest_people_search-card'} target="_blank" rel="noreferrer">
            <FaLinkedin
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}
            />
            </a>
            <a href={'https://www.youtube.com/channel/UCht6tNir_4ID7TyTHDVKEVg/videos'} target="_blank" rel="noreferrer">
            <FaYoutube
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}              
            />
            </a>
            <a href={'https://github.com/MarkDevereuxDeakin?tab=repositories'} target="_blank" rel="noreferrer">
            <FaGithub
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}              
            />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
