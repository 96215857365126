import React from "react";
import Navbar from "../components/Navbar";
import HomeImg from "../components/HomeImg";
import Footer from "../components/Footer";


const Home = () => {   
  
  return (
    
  <div>
      <Navbar />
      <HomeImg  />      
      <Footer />     
    </div>
    
  );
};

export default Home;
