import "./HomeStyles.css";
import WindowSize from "../utils/WindowSize";
import React from "react";

import SoftwareEngineer from "../assets/software_engineer.jpg";
import Mark from "../assets/mark.jpg";
import { Link } from "react-router-dom";

const HomeImg = () => {
  var {width} = WindowSize();
  var size = 20;

  if( width < 750 && width >250)
  {
    size = 21*width/750;    
  } 
  else if (width < 250)
  {
    size = 7;
  }

  return (
    <div className="hero">
      <div className="mask">
        <img className="into-img" src={SoftwareEngineer} alt="Software Engineer" />
      </div>      
      <div className="content" >
      <div class="center-image photo">
        <img src={Mark}/>
      </div>
        <h1 >HI, I'M MARK.</h1>
        <p style = {{fontSize: size }}>Application and Full Stack Web Developer.</p>
        <div>
          <Link to="/portfolio" className="btn">
            Portfolio
          </Link>          
        </div>
      </div>
    </div>
  );
};

/**<Link to="/contact" className="btn btn-light">
            Contact
          </Link> */

export default HomeImg;
