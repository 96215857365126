import pro1 from "../assets/project1.jpg";
import pro2 from "../assets/project2.jpg";
import pro3 from "../assets/project3.jpg";
import pro4 from "../assets/project4.jpg";
import pro5 from "../assets/project5.jpg";
import pro6 from "../assets/project6.jpg";

const ProjectCardData = [
  {
    imgsrc: pro1,
    title: "Tourism App",
    text: "Tourism App created for SIT305 Mobile App Development",
    view: "https://www.youtube.com/watch?v=KcUUW3fWkko",
  },
  {
    imgsrc: pro2,
    title: "Restaurant Finder App",
    text: "Restaurant Finding App created for SIT305 Mobile App Development",
    view: "https://www.youtube.com/watch?v=tTt96jTXiII&t=12s",
  },
  {
    imgsrc: pro3,
    title: "Quiz App",
    text: "Quiz App created for SIT305 Mobile App Development",
    view: "https://www.youtube.com/watch?v=n8yB45Z077M",
  },
  {
    imgsrc: pro4,
    title: "Note Taking App",
    text: "Note Taking App created for SIT305 Mobile App Development",
    view: "https://www.youtube.com/watch?v=sS1l7ldDYdE",
  },
  {
    imgsrc: pro5,
    title: "Workout Timer",
    text: "Workout Timer created for SIT305 Mobile App Development",
    view: "https://www.youtube.com/watch?v=oQvAzLccCk0",
  },
  {
    imgsrc: pro6,
    title: "Unit Converter",
    text: "Unit Converting App created for SIT305 Mobile App Development",
    view: "https://www.youtube.com/watch?v=cE6oWXJiID4",
  },   
];

export default ProjectCardData;
