import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutImg from "../components/AboutImg";
import AboutImg2 from "../components/AboutImg2";
import WindowSize from "../utils/WindowSize";

const About = () => {
  const firstLine = "I'm a 3rd year Bachelor of Information Technology student at Deakin University. \n I am completing a major in Application Development which covers mobile, \ndesktop and full stack web developer.\n";
  const secondLine = "I am also completing two minors in Netowrking and Cloud Technologies and Programming,\n which focuses on Concurrent and Distributed Programming and the Software Architecture of IoT.\n";
  const thirdLine = "Prior to commencing a Bachelor of Information Technology, I was a Clinical Pharmacist \nand worked in a variety of hospital settings including Internal Medicine, Cardiology,\n Nephrology and Paediatrics.\n";
  const fourthLine = "To become the Clinical Pharmacist I was, I completed a Bachelor of Neuroscience,\n Master of Pharmacy and a Graduate Diploma of Clinical Pharmacy while working fulltime.\n";
  
  var {width} = WindowSize();
  var size = 40;

  if( width < 750 && width > 500)
  {
    size = 40*width/750;    
  } 
  else if (width < 500)
  {
    size = 30;
  }



  return (
    <div>      
      <Navbar />
      <AboutImg heading="ABOUT" text={firstLine + secondLine + thirdLine + fourthLine} size = {size} />
      <Footer />          
    </div>
  );
};

export default About;
