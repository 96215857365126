import pro7 from "../assets/project7.jpg";
import pro8 from "../assets/project8.jpg";
import pro9 from "../assets/project9.jpg";
import pro10 from "../assets/project10.jpg";
import pro11 from "../assets/project11.jpg";
import pro12 from "../assets/project12.jpg";

const ProjectCardData = [
  {
    imgsrc: pro7,
    title: "iService Home Page",
    text: "iService is an 'AirTasker' style web application created for SIT313 Developing Client-Server Web Apps",
    view: "https://www.youtube.com/watch?v=eOOhhkhyUlo&t=3s",
  },
  {
    imgsrc: pro8,
    title: "iService Customer Registration Page",
    text: "iService is an 'AirTasker' style web application created for SIT313 Developing Client-Server Web Apps",
    view: "https://www.youtube.com/watch?v=aSIv2gjvAxo",
  },
  {
    imgsrc: pro9,
    title: "iService Customer Login Page",
    text: "iService is an 'AirTasker' style web application created for SIT313 Developing Client-Server Web Apps",
    view: "https://www.youtube.com/watch?v=0lH7QsPtEqM",
  },
  {
    imgsrc: pro10,
    title: "iService Welcome Email Setup",
    text: "iService is an 'AirTasker' style web application created for SIT313 Developing Client-Server Web Apps",
    view: "https://www.youtube.com/watch?v=pP2q5KDOHEc",
  },
  {
    imgsrc: pro11,
    title: "iService Customer Task Submission Page",
    text: "iService is an 'AirTasker' style web application created for SIT313 Developing Client-Server Web Apps",
    view: "https://www.youtube.com/watch?v=QoXzf4S2zvE&t=1s",
  },
  {
    imgsrc: pro12,
    title: "iService Find Task Page",
    text: "iService is an 'AirTasker' style web application created for SIT313 Developing Client-Server Web Apps",
    view: "https://www.youtube.com/watch?v=w5E2n_9CxNI",
  },      
];

export default ProjectCardData;
