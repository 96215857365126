import "./WorkCardStyles.css";
import WorkCard from "./WorkCard";
import GameMechanicsData from "./GameMechanicsData";

import React from "react";

const GameMechanics = () => {
  return (
    <div className="work-container">
      <h1 className="project-heading">Basic Prototype Game Mechanics</h1>
      <div className="project-container2">
        {GameMechanicsData.map((val, ind) => {
          return (
            <WorkCard
              key={ind}
              imgsrc={val.imgsrc}
              title={val.title}
              text={val.text}
              view={val.view}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GameMechanics;
