import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PortfolioImg from "../components/PortfolioImg";
import AndroidStudio from "../components/AndroidStudio";
import WebApps from "../components/WebApps";
import WindowSize from "../utils/WindowSize";

const Portfolio = () => {
  const firstLine = "Here is a collection of all the projects I have completed.\n Most of these are university projects; however, I am working to add my own completed projects soon.\n"
  const secondLine = "My own projects are significantly larger and more complicated, so they are taking just a little bit longer;\n however, you can get a preview on the Work in Progress page \n"
  
  var {width} = WindowSize();
  var size = 40;

  if( width < 750 && width > 500)
  {
    size = 40*width/750;    
  } 
  else if (width < 500)
  {
    size = 30;
  }

  return (
    <div>
      <Navbar />
      <PortfolioImg heading="PORTFOLIO" text={firstLine + secondLine} size = {size} />
      <AndroidStudio />
      <WebApps />     
      <Footer />      
    </div>
  );
};

export default Portfolio;
