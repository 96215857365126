import wc1 from "../assets/world1.jpg";
import wc2 from "../assets/world2.jpg";
import wc3 from "../assets/world3.jpg";
import wc4 from "../assets/world4.jpg";
import wc5 from "../assets/world5.jpg";
import wc6 from "../assets/world6.jpg";

const ProjectCardData = [
  {
    imgsrc: wc1,
    title: "Unity: Kyushu Island",
    text: "A basic, 32km x 32km Unity Terrain with weather from Enviro, using a heightmap of Kyushu that I created in World Machine and textured with Procedural Worlds Gaia.",
    view: "https://www.youtube.com/watch?v=WE7OR2f3ilQ",
  },
  {
    imgsrc: wc2,
    title: "Unity: Shrugland Steppe",
    text: "A shrubland steppe environment, using a World Machine generated heightmap, Gaia generated textures and populated with NatureManufacture assets and VSPro",
    view: "https://www.youtube.com/watch?v=iePxbRwN2nE&t=26s",
  },
  {
    imgsrc: wc3,
    title: "Unity: Shrugland Steppe 2",
    text: "A shrubland steppe environment, using a World Machine generated heightmap, Gaia generated textures and populated with NatureManufacture assets and VSPro",
    view: "https://www.youtube.com/watch?v=gnxoKy17Dco",
  },
  {
    imgsrc: wc4,
    title: "Unity: Fukue Island Forest",
    text: "A forest environment, using a World Machine generated heightmap of Fukue Jima, Gaia generated textures and populated with NatureManufacture assets and VSPro",
    view: "https://youtu.be/Xe0T9mu3fis",
  },
  {
    imgsrc: wc5,
    title: "ArcAGIS and Unity: Earth",
    text: "Using ArcAGIS with Unity, I render the island of Kyushu, the Japanese Archipelago and the whole world at full scale. A quick experiment with the ArcAGIS plugin.",
    view: "https://youtu.be/xDdtiB_HT4c",
  },
  {
    imgsrc: wc6,
    title: "Unreal 5: Coniferous forest",
    text: "Using a heightmap of Kyushu generated with World Machine, I procedurally generate, textures and a coniferous forest using MW's Coniferous Forest Biome.",
    view: "https://youtu.be/xDdtiB_HT4c",
  }
   
];

export default ProjectCardData;
