import gm1 from "../assets/game1.jpg";

const ProjectCardData = [
  {
    imgsrc: gm1,
    title: "Unity: Apothecary Mechanic",
    text: "A basic, first iteration of an Apothecary/Pharmaceutical Compounding mechnic",
    view: "https://www.youtube.com/watch?v=WE7OR2f3ilQ",
  }
   
];

export default ProjectCardData;
