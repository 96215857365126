import React from "react";
import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Portfolio from "./routes/Portfolio";
import WIP from "./routes/WIP";

import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/wip" element={<WIP/>} />
        <Route path="/about" element={<About />} />        
      </Routes>
    </>
  );
}
//<Route path="/contact" element={<Contact />} />

export default App;
